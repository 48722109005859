@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);

body {
	padding-top: 70px;
	font-family: 'Open Sans', sans-serif;
}

.navbar {
  margin-bottom: 20px;
}

.jumbotron {
	text-align: center;
	background: transparent;

	p {
		font-size: 14px;
	}
}

.btn-play {
	padding: 12px 6px;
}

.entry-box {
 height: 100px;
	font-size: 80px;
	padding: 0;
	// line-height: 1.33;
	text-align: center;
	font-family: Consolas,"Liberation Mono",Courier,monospace;
	color: #000;
}

.form-control {
	&.is-invalid {
		padding-right: initial;
		background: initial;
	}
}

.user-img {
  display: block;
  margin: auto;
  max-height: 400px;
}